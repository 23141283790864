/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import CollectionListingContainer from "@components/CollectionListingContainer";
import SEO from "@components/seo";
import React from "react";
const ArticleTemplate = (props) => {
  const { category } = props.data.craft;
  const { site } = props.data;

  return (
    <React.Fragment>
      <SEO
        metadata={{
          metaTitle: category.title,
          metaDescription: category.description ? category.description : "",
          socialImage: `${site.siteMetadata.hostname}/images/${encodeURI(
            category.title
          )}.jpg`,
        }}
        location={props.location}
      />
      <CollectionListingContainer category={category} listing="collections" />
    </React.Fragment>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query CollectionsQuery($id: Craft_QueryArgument) {
    craft {
      category(id: [$id]) {
        ... on Craft_collections_Category {
          title
          description
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        hostname
        image
      }
    }
  }
`;
